
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IDefaultConfigData, defaultConfigData } from '@/business/DefaultConfigData';
@Component({})
export default class ConfigForm extends Vue {
  @Prop() config?: IDefaultConfigData;
  @Prop() name?: string;
  @Prop() stage!: string;
  @Prop() subject!: string;

  configForm: IDefaultConfigData = defaultConfigData;
  configName = '';

  created () {
    this.configForm.grade = this.stage;
    this.configForm.subject = this.subject;
    if (this.config) {
      this.configForm = this.config;
      if (this.configForm.is_delete_kh === undefined) this.$set(this.configForm, 'is_delete_kh', false);
      if (this.configForm.is_auto_escape === undefined) this.$set(this.configForm, 'is_auto_escape', true);
    }
    if (this.name) {
      this.configName = this.name;
    }
  }

  getTextareaValue (arr: string[]) {
    return arr.join('\n');
  }

  setTextareaValue (val: string, type: 'title' | 'stu_qnum1' | 'stu_qnum2' | 'ans_qnum1' | 'ans_qnum2') {
    this.configForm[type] = val.split('\n');
  }

  saveHandle () {
    this.$emit('save', this.configForm, this.configName);
  }

  closeHandle () {
    this.$emit('close');
  }
}

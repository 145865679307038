import api from '../api';
import { IGetOrder, IOssInfo } from './types';
import { IOrderForm } from '@/types/placeOrderTypes';

/**
 * @description 获取学科
 * @param params {}
 * @return { Promise }
 */
export const getSubject = () => {
  return api.get('/api/worksheet/admin/subject/v1/get', { academic: 'k12' });
};

/**
 * @description 获取学段
 * @param params {}
 * @return { Promise }
 */
export const getStage = () => {
  return api.get('/api/worksheet/admin/stage/v1/get');
};

/**
 * @description 获取工单信息
 * @param params {}
 * @return { Promise }
 */
export const getOrder = (params: IGetOrder) => {
  return api.get('/api/worksheet/admin/ticket/v1/handle', params);
};

/**
 * @description 获取公司或部门的工作流配置
 */
export const getOrgConfig = (params: any) => {
  return api.get('http://publish.hexinedu.com/api/project/customer/config', params);
};

/**
 * @description 修改公司或部门的工作流配置
 */
export const setOrgConfig = (params: any) => {
  return api.post('http://publish.hexinedu.com/api/project/customer/config', params);
};

/**
 * @description 创建工单
 * @param params
 * @return { Promise }
 */
export const creatOrder = (data: IOrderForm) => {
  return api.post('/api/worksheet/admin/ticket/v1/handle', data);
};

/**
 * @description 更新工单
 * @param params
 * @return { Promise }
 */
export const editOrder = (data: IOrderForm) => {
  return api.put('/api/worksheet/admin/ticket/v1/handle', data);
};

/**
 * @description 获取ossInfo
 * @param params ticket_id
 * @return { Promise }
 */
export const getOssInfo = (params: IOssInfo) => {
  return api.get('/api/worksheet/admin/content/v1/get_oss_info', params);
};

/**
 * @description 提交工单
 * @param params ticket_id
 * @return { Promise }
 */
export const submitOrder = (data: {ticket_id: string}) => {
  return api.post('/api/worksheet/admin/ticket/v1/place_order', data);
};

/**
 * @description 获取用户下的应用
 * @param params { customer_id:string }
 * @return { Promise }
 */
export const getCustomerApp = (params: {customer_id: string}) => {
  return api.get('/api/worksheet/admin/account/v1/application/get_all_by_customer', params);
};

/**
 * @description  MD5 获取“文件”
 * @param params md5
 * @return { Promise }
 */
export const getMaterialFile = (params: {md5: string}) => {
  return api.get('/api/worksheet/admin/content/v1/file', params);
};

/**
 * @description 创建（上传）“文件”
 * @param params ticket_id
 * @return { Promise }
 */
export const creatMaterialFile = (data: {md5: string; path: string; name: string; extension: string}) => {
  return api.post('/api/worksheet/admin/content/v1/file', data);
};

/**
 * @description 生成软链接
 * @param params ticket_id
 * @return { Promise }
 */
export const creatMaterialFileSymlink = (data: {ticket_id: string; origin_path: string; name: string; path_alias: string}) => {
  return api.post('/api/worksheet/admin/content/v1/symlink', data);
};

/**
 * @description 文件上传失败打日志
 * @param params user_id
 * @return { Promise }
 */
export const setUploadHistory = (data: {user_id: number; username: string; ticket_id: string; type: number; ext: any}) => {
  return api.post('/api/worksheet/admin/log/v1/history', data);
};

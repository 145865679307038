export interface IDefaultConfigData {
  debug_info: number;
  grade: string;
  subject: string;
  book_type: string;
  insert_mode: string;
  opt_layout: boolean;
  answer_analysis_exchange: boolean;
  ans_image_color: boolean;
  blank_first_char: string;
  // rm_blank_first_char: boolean;
  blank_JY_set: boolean;
  choice_mode: string;
  choice_blank_no_exp: boolean;
  other_quest_exp_after_all: boolean;
  chem_right_arrow_as_blank: boolean;
  style_bracket: string[];
  style_blank: string[];
  style_answer: string[];
  style_analysis: string[];
  style_long_blank: string[];
  style_choice: string[];
  slot_replace: boolean;
  slot_style_bracket: string[];
  slot_style_blank: string[];
  slot_style_answer: string[];
  slot_style_analysis: string[];
  slot_style_long_blank: string[];
  slot_style_choice: string[];
  title: string[];
  stu_qnum0: string[];
  stu_qnum1: string[];
  stu_qnum2: string[];
  stu_qnum3: string[];
  stu_blank: string[];
  stu_bracket: string[];
  ans_qnum0: string[];
  ans_qnum1: string[];
  ans_qnum2: string[];
  ans_qnum3: string[];
  fix_space_count: number;
  is_delete_kh: boolean;
  is_auto_escape: boolean;
}
export const defaultConfigData: IDefaultConfigData = {
  // 全局配置
  debug_info: 1,
  grade: 'primary', // 年级, primary, junior, senior
  subject: 'chinese', // 学科，english 或其他
  book_type: 'exerices', // 纯练习册 or 讲解书, 方便 fake qnum 判断
  opt_layout: false, // 版面优化：True/False；针对 LM 范围内内容，进行版面紧缩
  answer_analysis_exchange: false, // 答案解析位置对掉（radiobox，答案解析 False，解析答案 True）；试题答案、解析插入顺序调整
  ans_image_color: false, // 答图替换颜色：True/False，修改答案图片颜色（客户需求）, 支持 CMYK & RGB 模式 [100, 0, 0, 0]

  // 选择填空配置
  insert_mode: 'in_blank', // 填空题插入模式：在空格里、在试题后；插入模式 in_blank or after_quest
  blank_first_char: 'keep', // 填空题首字母，三个模式，保留现状（keep），去掉答案首字母（remove），去掉空格前字母（merge）
  // rm_blank_first_char: false, // 填空答案首字母过滤：True/False；只针对英语学科有效，开启单词首字母判断优化
  blank_JY_set: false, // 填空横线 JY 撑满：True/False，居右撑满 True，普通填空 False; 针对一些题目，不设置居右操作
  choice_mode: 'normal', // 选择答案插入：正常插入，用颜色表达答案，在试题后，在试题前（英语学科有效）before_qnum or normal or color_option or after_quest
  choice_blank_no_exp: true, // 选择填空是否添加解析：True/False，填空、选择是否加解析, 万唯要求
  other_quest_exp_after_all: true, // 作答题答案解析首次在小题后：True/False, 计算题解析不在小题后
  chem_right_arrow_as_blank: false, // 化学右箭头也可以填答案：True/False

  // 默认样式
  style_bracket: ['（〖CSX%100，0，0，0〗〖CS%100,0,0,0〗〓%s〓〖CS〗〖CSX〗）'],
  style_blank: ['〖ZZ(Z〗〖CS%100,0,0,0〗〖CSX%100,0,0,0〗〓%s〓〖CSX〗〖CS〗〖CSX〗〖ZZ)〗'],
  style_answer: ['〖CS%100,0,0,0〗〖CSX%100,0,0,0〗〖HTH〗解:〖HT5SS〗%s〖CSX〗〖CS〗'],
  style_analysis: ['〖CS%100,0,0,0〗〖CSX%100,0,0,0〗〖HTH〗解析:〖HT5SS〗%s〖CSX〗〖CS〗'],
  style_long_blank: ['〖ZZ(Z〗〓〖KG-*2〗〖WTHZ〗〖CS%0,100,0,0〗〖HTHB〗〖STHZ〗%s〖STBZ〗〖HT〗〖WT〗〖CS〗〖JY(〗〖JY)〗〖ZZ)〗'], // 长空格
  style_choice: ['〖CS%0,100,0,0〗〖CSX%0,100,0,0〗〖WT5HZ〗〖STHZ〗%s〖WTBZ〗〖STBZ〗〖CSX〗〖CS〗'], // 选项参数，只在 choice_mode 为 color_option 生效

  // 自定义样式配置
  slot_replace: false,
  slot_style_bracket: ['〖=JCXZ(〗%s〖=〗'], // 括号样式，一个 inputbox，只允许插入一个即可
  slot_style_blank: ['〖=JCHX(〗%s〖=〗'], // 填空样式
  slot_style_answer: ['〖=JCDT(〗%s〖=〗'], // 答案样式
  slot_style_analysis: ['〖CSD%0,0,0,5〗〖FK(WB8001。78mmZQ〗〖HTH〗%s〖CS〗〖HT〗〖FK)〗〖CSD〗'], // 解析样式
  slot_style_long_blank: ['〖ZZ(Z〗〓〖KG-*2〗〖WTHZ〗〖CS%0,100,0,0〗〖HTHB〗〖STHZ〗%s〖STBZ〗〖HT〗〖WT〗〖CS〗〖JY(〗〖JY)〗〖ZZ)〗'], // 长空格
  slot_style_choice: ['〖CS%0,100,0,0〗〖CSX%0,100,0,0〗〖WT5HZ〗〖STHZ〗%s〖WTBZ〗〖STBZ〗〖CSX〗〖CS〗'], // 选项参数，只在 choice_mode 为 color_option 生效

  // 标题配置
  title: [],
  stu_qnum0: [],
  stu_qnum1: [],
  stu_qnum2: [],
  stu_qnum3: [],
  stu_blank: [],
  stu_bracket: [],
  ans_qnum0: [],
  ans_qnum1: [],
  ans_qnum2: [],
  ans_qnum3: [],

  // 特殊配置
  fix_space_count: 0, // 统一特定区域横线长度
  is_delete_kh: false, // 是否全局删除KH命令
  is_auto_escape: true, // 是否自动转义
};

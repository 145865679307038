import api from './api';

const getPublishUrl = (url: string) => {
  const isInstra = location.origin.indexOf('hexin.im') > 0 || location.origin.indexOf('localhost') > 0 || location.origin.indexOf('192.') > 0;
  const suffix = isInstra ? 'http://publish.hexin.im' : 'http://publish.hexinedu.com';
  return suffix + url;
};

const getFounderUrl = (url: string) => {
  const isInstra = location.origin.indexOf('hexin.im') > 0 || location.origin.indexOf('localhost') > 0 || location.origin.indexOf('192.') > 0;
  const suffix = isInstra ? 'http://founder.hexin.im' : 'http://founder.hexinedu.com';
  return suffix + url;
};

export const getOrder = (params: { customer_id: string }) => {
  return api.get('/api/worksheet/admin/ticket/v1/search', params);
};

export const getDeliver = (params: { ticket_id: string }) => {
  return api.get('/api/worksheet/admin/content/v1/deliverable', params);
};

export const getVerifyDeliver = (params: { ticket_id: string; process_name?: string }) => {
  return api.get('/api/worksheet/admin/content/v1/deliverable/verification', params);
};

/**
 * 获取点数
 */
export const getDeductPoint = (data: {ticket_id: string; point_type: string}) => {
  return api.post('http://worksheet.hexinedu.com/api/admin/ticket/v1/deduct_point', data);
};

export const rerunStu2TeaOrder = (params: { ticket_id: string; run_type: string }) => {
  return api.post(getPublishUrl('/api/fbd_align/task/open/generate'), params, { withCredentials: false });
};

export const rerunWord2FbdOrder = (params: { ticket_id: string; run_type: string }) => {
  return api.post(getPublishUrl('/api/word_fbd/task/open/generate'), params, { withCredentials: false });
};

export const rerunOrderStatus = (params: { ticket_id: string; status?: number }) => {
  return api.post('/api/worksheet/admin/ticket/v1/rerun', params, { withCredentials: false });
};

export const reRunWord2PPTTask = (data: any) => {
  return api.post('http://publish.hexinedu.com/api/word_ppt/project/rerun', data);
};

export const reRunJSON2PPTTask = (data: any) => {
  return api.post('http://publish.hexinedu.com/api/json_ppt/project/batch/rerun', data);
};

export const getStyleList = (params: { type: any; size: any; limit: number; skip: number; customer_id: number; search?: any }) => {
  return api.get(getPublishUrl('/api/pool/style/open/list'), params, { withCredentials: false });
};

export const getStyleInfo = (params: {}) => {
  return api.get(getPublishUrl('/api/pool/style/info'), params, { withCredentials: false });
};

export const getStyleConfig = (params: {}) => {
  return api.get(getPublishUrl('/api/pool/style/open/get'), params, { withCredentials: false });
};

export const creatStyle = (params: {}) => {
  return api.post(getPublishUrl('/api/pool/style/open/create'), params, { withCredentials: false });
};

export const copyStyle = (params: {}) => {
  return api.post(getPublishUrl('/api/pool/style/copy'), params, { withCredentials: false });
};
export const copyFBDStyle = (params: {}) => {
  return api.post(getPublishUrl('/api/fbd_align/style/copy'), params, { withCredentials: false });
};

export const editStyle = (params: {}) => {
  return api.post(getPublishUrl('/api/pool/style/open/edit'), params, { withCredentials: false });
};

export const getFbdStyleList = (params: { limit: number; skip: number; customer_id: number; search?: any }) => {
  return api.get(getPublishUrl('/api/fbd_align/style/list'), params, { withCredentials: false });
};

export const getFbdStyleConfig = (params: {}) => {
  return api.get(getPublishUrl('/api/fbd_align/style/info'), params, { withCredentials: false });
};

export const creatFbdStyle = (params: {}) => {
  return api.post(getPublishUrl('/api/fbd_align/style/create'), params, { withCredentials: false });
};

export const editFbdStyle = (params: {}) => {
  return api.post(getPublishUrl('/api/fbd_align/style/edit'), params, { withCredentials: false });
};

export const deleteFbdStyle = (params: {}) => {
  return api.post(getPublishUrl('/api/fbd_align/style/delete'), params, { withCredentials: false });
};

export const getDraftingImageList = (params: {}) => {
  return api.get(getPublishUrl('/api/image_gallery/image/get_all'), params, { withCredentials: false });
};

export const getDraftingProjectInfo = (params: {}) => {
  return api.get(getPublishUrl('/api/image_gallery/image/project/info'), params, { withCredentials: false });
};

export const creatGalleryProject = (params: {}) => {
  return api.post(getPublishUrl('/api/image_gallery/image/gallery/generate'), params, { withCredentials: false });
};

export const editDraftingProject = (params: {}) => {
  return api.post(getPublishUrl('/api/image_gallery/image/project/edit'), params, { withCredentials: false });
};

export const uploadStyle = (params: {}) => {
  return api.post(getFounderUrl('/api/sigma_xml2fbd/style/generate_temp_style'), params);
};
